import styled from 'styled-components'

const HeaderStyle = styled.div`
    background-color : #06384D;
    height:7vh;
    width:100vw;
    color : white;
    display:flex;
    flex-direction:row;   
    justify-content:space-between; 
    align-items:center;
    padding-left:25px;
    padding-right:25px;
    
    button{
        width:80px;
        height:60%;
        border-radius:10px;
        border: none;
        &:hover{
            cursor:pointer;
        }
    }
    img{
        height:70%;
    }
`
export default HeaderStyle