import React from 'react'
import Logo from '../assets/logo.png'
import LoginStyle from './styles/Login.styled'

function Login(props) {
    return (
        <LoginStyle>
            <img className='Logo' src={Logo} alt="Gitlab Attributes Bridge"></img>
            <button className='Button' onClick={props.auth.signinRedirect}>Connect with gitlab</button>
        </LoginStyle>
    )
}

export default Login