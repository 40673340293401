import React from 'react'
import { HistoryStyle, CellStyle } from './styles/History.styled';

const format = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:"numeric", minute:"numeric" };

function History(props) {

    const historiesRender = React.useMemo( () =>{
        if(!Array.isArray(props.history))
            return <></>
        const histories = props.history.map((modification) =>
            <CellStyle className='history-cell' key={modification.timestamp.toString()}>
                <div className='date'>
                    {new Date(modification.timestamp).toLocaleDateString("en-EN",format) }<br/>
                </div>
                <div className='editor'>
                    {modification.editor}
                </div>
                <div className='value'>
                    {modification.value}
                </div>
            </CellStyle>
        );
        return histories
        
    }, [props.history])

    
  return (
    <HistoryStyle>
        {historiesRender}
    </HistoryStyle>
  )
}

export default History