import React from 'react'
import { useTable, useBlockLayout,useSortBy,useFilters } from 'react-table'
import { FixedSizeList } from 'react-window'
import scrollbarWidth from './scrollbarWidth'
import { TableStyle } from './styles/Table.styled'

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
}

function Table({ columns, data, loading, updateMyData, showHistory }) {
  // Use the state and functions returned from useTable to build your UI

  const defaultColumn = React.useMemo(
    () => ({
        Filter: DefaultColumnFilter
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      showHistory
    },
    useBlockLayout,
    useFilters,
    useSortBy
  )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div className='tr'
          {...row.getRowProps({
            style,
          })}
        >
          {row.cells.map(cell => {
            return (
              <div className='td' {...cell.getCellProps()}>
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  // Render the UI for your table
  return (
    <TableStyle>
      <div className='table' {...getTableProps()}>
        <div className='thead'>
          {headerGroups.map(headerGroup => (
            <div className='tr' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map(column => (
                <div className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}{column.isSorted
                        ? column.isSortedDesc
                          ? '🔽'
                          : '🔼'
                        : ''}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          <FixedSizeList
            height={700}
            itemCount={rows.length}
            itemSize={55}
            width={totalColumnsWidth+scrollBarSize}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
      </div>
    </TableStyle>
    
  )
}

export default Table