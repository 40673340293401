import React from 'react'
import LogoHeader from '../assets/logo-header.svg'
import HeaderStyle from './styles/Header.styled'

function Header(props) {
    const auth = props.auth
    return (
        <HeaderStyle>
            <img src={LogoHeader}alt="Gitlab Attributes Bridge"></img>
            <div>Hello {auth.user?.profile.email}</div>
            <button onClick={auth.removeUser}>Log out</button>
        </HeaderStyle>
    )
}

export default Header