import styled from 'styled-components'

const LoginStyle = styled.div`
    background-color : #102832;
    height:100vh;
    color : black;
    display:flex;
    flex-direction:column;   
    justify-content:center; 
    align-items:center;

    .Logo{
        width:400px;
    }
    .Button{
        width:240px;
        height:30px;
        border-radius:10px;
        &:hover{
            cursor:pointer;
        }
    }
`
export default LoginStyle