import styled from 'styled-components'

const DashboardStyle = styled.div`
  background-color : #102832;
  height:100vh;
  width:100vw;
  color : black;
  display:flex;
  flex-direction:column;

  .wrapper{
    display:flex;
    flex-direction:row;
  }

  input{
      background-color: #102832;
      border:none;
      width: 98%;
      height: 100%;
      text-align:center;
      font-weight:700;
  }
`

export default DashboardStyle