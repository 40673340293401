import React, { useState, useEffect } from "react";
import Table from "../Components/Table";
import EditableCell from "../Components/EditableCell";
import History from "../Components/History";
import Api from "../Api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardStyle from "./styles/Dashboard.styled";
import showError from "../services/Toast";

function Dashboard(props) {
  const api = new Api(props.access_token, props.applicationConfig.backend_url);
  let keys = props.applicationConfig.columns.projects.custom_attributes;

  let _columns = [];

  for (let key of keys) {
    _columns.push({
      Header: key,
      accessor: "custom_attributes." + key,
      Cell: EditableCell,
    });
  }

  const fetchEntitiesWithin = async (page, params, id) => {
    let apiRes = null;
    try {
      apiRes = await api.getEntitiesWithin(page, params, id);
    } catch (err) {
      apiRes = err.response;
    } finally {
      if (apiRes.status === 404) {
        showError(`GitLab ${params.parent} :  ${id}  not found`);
        return;
      }
      if (apiRes.status === 200) {
        setData((data) => [...data, ...apiRes.data]);
      }
    }
  };

  const fetchGroupProjectCount = async (params, id) => {
    const response = await api
      .getGroupProjectsCount(params, id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => showError("Error while fetching entities count"));
    return response;
  };

  const fetchAllWithin = async ({ parent, ids, child } = {}) => {
    setLoading(true);
    let params = { per_page: 20, entities: child, parent: parent };

    for (let id of ids) {
      const entities_count = await fetchGroupProjectCount(params, id);
      const pages_count = Math.ceil(entities_count / params.per_page) + 1;
      for (let page = 1; page < pages_count; page++) {
        await fetchEntitiesWithin(page, params, id);
      }
    }
    setLoading(false);
  };

  const updateCustomAttribute = async (entities, entity_id, key, value) => {
    api
      .updateCustomAttribute(entities, entity_id, key, value)
      .then((response) => console.log(response.data))
      .catch((err) => showError("Error : custom attribute not updated"));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Projects",
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => (
              <a target="_blank" rel="noopener noreferrer" href={row.original.web_url}>
                {row.original.name}
              </a>
            ),
          },
          {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }) => value.split("T")[0],
          },
        ],
      },
      {
        Header: "Custom Attributes",
        columns: _columns,
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);

  const showHistory = (history) => {
    setHistory(history);
  };

  const updateMyData = (rowIndex, columnId, history, new_value) => {
    let entity_id = data[rowIndex].id;
    const key = columnId.split(".")[1];

    let new_history = JSON.parse(JSON.stringify(history));
    new_value.editor = props.user;
    new_history.push(new_value);
    const value = JSON.stringify(new_history);
    updateCustomAttribute("projects", entity_id, key, value);
  };

  useEffect(() => {
    setData([]);
    fetchAllWithin({ parent: "groups", ids: props.applicationConfig.group_ids, child: "projects" });
  }, []);

  return (
    <DashboardStyle>
      <ToastContainer />

      <div className="wrapper">
        <Table columns={columns} data={data} loading={loading} updateMyData={updateMyData} showHistory={showHistory} />
        <History history={history}></History>
      </div>
    </DashboardStyle>
  );
}

export default Dashboard;
