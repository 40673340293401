import axios from 'axios'

export default class Api {

    constructor(access_token, api_url) {
        this.access_token = access_token;
        this.client = null;
        this.api_url = api_url
    }
    init = () => {
      let headers = {
        Accept: "application/json",
        Authorization: `Bearer ${this.access_token}`
      };
      this.client = axios.create({baseURL: this.api_url, timeout: 80000, headers: headers});
      this.client.interceptors.response.use(response => {
        return response;
     }, error => {
       if (error.response.status === 401) {
        console.log("error 401")
       }
       if (error.response.status === 404) {
        return error.response.status
       }
     });
      return this.client;
    };
    getEntities = (params) => {
      return this.init().get(`/${params.entities}?per_page=${params.per_page}&page=${params.page}`);
    };
    getEntitiesWithin = (page, params, id) => {
      return this.init().get(`/${params.parent}/${id}/${params.entities}?per_page=${params.per_page}&page=${page}`);
    };
    getEntitiesCount = (entities) =>{
        return this.init().get(`/${entities}/count`)
    }
    getGroupProjectsCount = (params, id) =>{
      return this.init().get(`/${params.parent}/${id}/${params.entities}/count`)
  }
    updateCustomAttribute = (entities, entity_id, key, value) =>{
        return this.init().put(`/${entities}/${entity_id}/${key}`,{"value":value})
    }
  }
