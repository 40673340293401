import styled from 'styled-components'

export const TableStyle = styled.div`

  width:100%;
  height:100%;
  overflow-x: auto;

  .tr {
    background-color: #E7E7E7;
    input{
      cursor:default;
      &:hover{
        border-radius:2px;
        background-color: #E0E0E0;
      }
      &:focus{
        cursor:text;
      }
      background-color: #E7E7E7;
    }
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th{
      color : white;
      background-color:#058E72;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      text-align:center;
      font-weight: 500;
  }

  .th,
  .td {
    display:flex;
    align-items:center;
    justify-content: center;
    padding: 0.2rem;
    text-align:center;

  }
`