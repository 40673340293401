// src/App.jsx
import React from "react"
import { useAuth } from "react-oidc-context"
import LoginPage from './pages/Login'
import Home from './pages/Home'
import './App.css'

function App(props) {
    const auth = useAuth()    
    
    React.useEffect(() => {
        auth.signinSilent()
        return auth.events.addAccessTokenExpiring(() => {
            if (alert("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
                auth.signinSilent()
            }
        })
    }, [auth.events, auth.signinSilent])
    
    if (auth.isLoading) {
        return <div>Loading...</div>
    }
    
    if (auth.error) {
        return <LoginPage auth={auth}></LoginPage>
    }
    
    if (auth.isAuthenticated) {
        return <Home auth={auth} applicationConfig={props.applicationConfig}></Home>
    }

    return <LoginPage auth={auth}></LoginPage>
}

export default App