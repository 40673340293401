import styled from 'styled-components'


export const CellStyle = styled.div`
    padding: 1vw;
    background-color : #E7E7E7;
    width:100%
    height:15vh;
    display:flex;
    flex-direction:column;
    border-radius:7px;
    justify-content:center;
    align-items:center;
    text-align:center;
    margin-bottom:15px;
    .date{
        color:grey;
        font-style: italic;
    }
    .editor{
        color:darkblue;
        font-size:0.8em;
        font-style: italic;
    }
`
export const HistoryStyle = styled.div`
    width:25vw;
    height:100%;
    display:flex;
    justify-content:flex-start;
    flex-direction:column;
    padding:1vw;
`
