import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthProvider } from "react-oidc-context"
import App from './App'
import { getFrontendConfigJson } from './services/service';

const render = async () =>{
  const jsonConfig = await getFrontendConfigJson();

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <AuthProvider {...jsonConfig.oidcConfig}>
      <App applicationConfig={jsonConfig.applicationConfig}/>
    </AuthProvider>
  );
}

render()

