import axios from "axios";
const isRegularStart = process.env.START_MODE === "regular";

// Request wrapper
const requestWrapper = axios.create({
  withCredentials: true,
  baseURL: isRegularStart ? "/" : process.env.PROJECT_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getFrontendConfigJson = async () => {
  let path = window.location.origin;
  let config = await requestWrapper
    .get(`${path}/config/config.json`)
    .then((response) => response?.data)
    .catch((error) => {
      console.error(error);
      return { error };
    });
  if (config.error) {
    config = await requestWrapper
      .get(`${path}/config/config.example.json`)
      .then((response) => response?.data)
      .catch((error) => console.error(error));
  }
  return config;
};
