import React, {useState, useEffect} from "react"


const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
    showHistory 
  }) => {
    const [cellValue, setCellValue] = useState("")
    let initialState = []
    try{
      initialState = JSON.parse(initialValue)
    }catch(error){
      initialState = []
    }
    const [history, setHistory] = useState(initialState)
  
    const onChange = e => {
      setCellValue(e.target.value)
    }

    const onClick = e => {
      showHistory(history);
    }
  
    const onKeyUp = e => {
        if(e.code ==="Escape"){
            e.target.blur();
            setCellValue(initialValue[0].value)
        }
        else if(e.code === "Enter"){
            e.target.blur();
            const new_value = {timestamp:Date.now(), value: e.target.value};
            setHistory(history => ([...history, ...[new_value]]))
            updateMyData(index, id, history, new_value);
            setCellValue(e.target.value)
        }
    }
  
    useEffect(() => {
      if(history.length > 0){
          history.sort((a,b) => b.timestamp - a.timestamp);
          setCellValue(history[0].value)
      }

        showHistory(history)
    }, [showHistory, history])
  
    return <input className='cell' value={cellValue} onClick={onClick} onChange={onChange} onKeyUp={onKeyUp}/>;
  }
export default EditableCell